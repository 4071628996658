// search

const search = document.querySelector('.search');
const searchBtn = document.querySelector('.btn-search');

searchBtn.addEventListener('click', () => {
	document.querySelector('body').classList.toggle('search-open');
});

// nav-trigger

const navTrigger = document.querySelector('.btn-hamburger');

navTrigger.addEventListener('click', () => {
	document.querySelector('body').classList.remove('search-open');
});

// Scroll-to-top button

$(document).ready(function () {
	// Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
	var scroll_to_top_button = [
		'<div class="scroll-to-top"><i class="fa-solid fa-chevron-up"></i></div>',
	].join('');
	$('body').append(scroll_to_top_button);

	// Der Button wird ausgeblendet
	$('.scroll-to-top').hide();

	// Funktion für das Scroll-Verhalten
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 200) {
				// Wenn 200 Pixel gescrollt wurde
				$('.scroll-to-top').fadeIn();
			} else {
				$('.scroll-to-top').fadeOut();
			}
		});

		$('.scroll-to-top').click(function () {
			// Klick auf den Button
			$('body,html').animate({
				scrollTop: 0,
			});
			return false;
		});
	});
});
